import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-container-picker" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "c-container-swiper" }
const _hoisted_4 = { class: "c-filter__day" }
const _hoisted_5 = { class: "c-filter__label" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "c-button c-button--previous",
      disabled: _ctx.previousDayIsDisabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPreviousDay()))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "icon-chevron_big_left" }, null, -1)
    ]), 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_swiper, {
        modules: _ctx.modules,
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        class: "c-swiper",
        onSwiper: _ctx.swiperInit
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: index,
              class: "c-days-picker",
              onClick: ($event: any) => (_ctx.onDayClick(day, index))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["c-filter", {
              'c-filter--active': index == _ctx.daySelected,
              'c-filter--enabled': day.active,
              'c-filter--disabled': !day.active,
            }])
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(day.letter), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(day.number), 1)
                ], 2)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules", "onSwiper"])
    ]),
    _createElementVNode("button", {
      class: "c-button c-button--next",
      disabled: _ctx.nextDayIsDisabled,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNextDay()))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("i", { class: "icon-chevron_big_right" }, null, -1)
    ]), 8, _hoisted_6)
  ]))
}