import { defineStore } from 'pinia';
import moment from 'moment';
import dateFormat from '@/helpers/DateFormat.helper';
export const useWearablesStore = defineStore('wearables-store', {
  state: () => ({
    wearablesSelectedDates: {
      startDate: '',
      endDate: '',
    },
  }),
  getters: {
    getWearablesSelectedStartDate: (applicationState: any) =>
      applicationState.wearablesSelectedDates.startDate
        ? applicationState.wearablesSelectedDates.startDate
        : dateFormat.startOfCertainDay(moment().format('YYYY-MM-DDTHH:mm:ss')),
    getWearablesSelectedEndDate: (applicationState: any) =>
      applicationState.wearablesSelectedDates.endDate
        ? applicationState.wearablesSelectedDates.endDate
        : dateFormat.endOfCertainDay(moment().format('YYYY-MM-DDTHH:mm:ss')),
  },
  actions: {
    setWearablesSelectedDates(payload: { startDate: string; endDate: string | null }) {
      this.wearablesSelectedDates = {
        startDate: payload.startDate ?? '',
        endDate: payload.endDate ?? '',
      };
    },

    resetWearablesSelectedDates() {
      this.wearablesSelectedDates = {
        startDate: '',
        endDate: '',
      };
    },
  },
});
