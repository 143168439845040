
import { ref, Ref, watchEffect, computed } from 'vue';
import { defineComponent } from '@vue/runtime-core';
import moment from 'moment';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/swiper-vue';
import { Virtual } from 'swiper/modules';
import 'swiper/swiper.css';
import { calculateListDays, DayPick } from '@/helpers/DaysPicker.helper';

export default defineComponent({
  components: { Swiper, SwiperSlide },
  emits: ['onChange'],
  props: {
    firstDayPicker: {
      type: String,
      required: true,
    },
    numDays: {
      type: Number,
      required: true,
    },
    defaultDay: {
      type: String,
      required: true,
    },
    limitDay: {
      type: String,
      default: () => '',
    },
  },
  setup(props, context) {
    const daySelected = ref(0);
    const days: Ref<DayPick[]> = ref(fetchDays());
    const swiperInstance = ref();

    const swiperInit = (swiper: any) => {
      swiperInstance.value = swiper;
    };

    function fetchDays() {
      moment.locale(i18n.global.locale.value);
      const dates = calculateListDays(props.firstDayPicker, props.limitDay, props.numDays);

      daySelected.value = dates.findIndex(
        (date: DayPick) => moment(date.dateStart).format('YYYY-MM-DD') == moment(props.defaultDay).format('YYYY-MM-DD'),
      );

      if (daySelected.value != undefined && daySelected.value >= 0) {
        context.emit('onChange', dates[daySelected.value]?.dateStart, dates[daySelected.value]?.dateEnd);
      }

      return dates;
    }

    const previousDayIsDisabled = computed(() => {
      return daySelected.value == 0;
    });

    const nextDayIsDisabled = computed(() => {
      if (daySelected.value == days.value.length - 1) return true;
      if (!days.value[daySelected.value + 1].active) return true;
      return false;
    });

    const onDayClick = async (date: DayPick, index: number) => {
      if (!date.active) return;
      daySelected.value = index;
      emitUpdateData(date);
    };

    const onNextDay = () => {
      if (daySelected.value == days.value.length - 1) return;
      if (!days.value[daySelected.value + 1].active) return;
      daySelected.value = daySelected.value + 1;
      emitUpdateData(days.value[daySelected.value]);
    };

    const onPreviousDay = () => {
      if (daySelected.value == 0) return;
      daySelected.value = daySelected.value - 1;
      emitUpdateData(days.value[daySelected.value]);
    };

    const emitUpdateData = (date: DayPick) => {
      context.emit('onChange', date.dateStart, date.dateEnd);
    };

    const updateSlider = (index: number) => {
      if (!swiperInstance.value) return;
      swiperInstance.value.slideTo(index, 0);
    };

    watchEffect(
      () => {
        updateSlider(daySelected.value);
      },
      { flush: 'post' },
    );

    return {
      days,
      onDayClick,
      daySelected,
      swiperInstance,
      swiperInit,
      onNextDay,
      onPreviousDay,
      nextDayIsDisabled,
      previousDayIsDisabled,
      modules: [Virtual],
    };
  },
});
