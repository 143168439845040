import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import DaysListPickerSwiper from '@/components/common/DaysListPickerSwiper.vue';
import WeekDaysListPickerSwiper from '@/components/common/WeekDaysListPickerSwiper.vue';
import moment from 'moment';
import { computed, nextTick, ref } from 'vue';
import i18n from '@/i18n';
import { useWearablesStore } from '@/store/wearables.module';
import dateFormat from '@/helpers/DateFormat.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'DaysPickerWithMonths',
  props: {
  initialDay: {
    type: String,
    required: false,
    default: '2022-03-15',
  },
  weekOrDays: {
    type: String,
    required: false,
    default: 'days',
  },
},
  emits: ['onDayPickerChanged'],
  setup(__props, { emit: __emit }) {

const wearablesStore = useWearablesStore();
const props = __props;
const emits = __emit;

const isDaysLoading = ref(false);

const selectedStartDate = computed(() => wearablesStore.getWearablesSelectedStartDate);

const firstDaySelectedMounth = computed(() =>
  moment(selectedMonth.value, 'M/YYYY').startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
);
const lastDaySelectedMounth = computed(() =>
  moment(selectedMonth.value, 'M/YYYY').endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
);
const numDaysInSelectedMonth = computed(() => moment(selectedMonth.value, 'M/YYYY').daysInMonth());

const selectedMonth = computed(() => moment(selectedStartDate.value).format('M/YYYY'));

const getMonthsOptions = computed(() => {
  moment.locale(i18n.global.locale.value);
  const months: { label: string; value: string }[] = [];
  const startDate = moment(props.initialDay);
  const endDate = moment().add(1, 'year');

  while (startDate.isSameOrBefore(endDate, 'month')) {
    const label: string = startDate.format('MMMM YYYY');
    const value: string = startDate.clone().format('M/YYYY');

    months.push({ label: label, value: value });
    startDate.add(1, 'month');
  }

  return months.reverse();
});

const onChangeSelectedMonth = async (month: string) => {
  isDaysLoading.value = true;
  const selectedMonthMoment = moment(month, 'M/YYYY');
  const todayDate =
    selectedMonthMoment.isSame(moment(), 'month') && selectedMonthMoment.isSame(moment(), 'year')
      ? moment().format('YYYY-MM-DDTHH:mm:ss')
      : selectedMonthMoment.startOf('month').format('YYYY-MM-DDTHH:mm:ss');

  wearablesStore.setWearablesSelectedDates({
    startDate: dateFormat.startOfCertainDay(todayDate),
    endDate: dateFormat.endOfCertainDay(todayDate),
  });

  await nextTick(() => {
    isDaysLoading.value = false;
  });
};

const onDayChanged = (dayStart: string, dayEnd: string) => {
  wearablesStore.setWearablesSelectedDates({
    startDate: dayStart,
    endDate: dayEnd,
  });

  emits('onDayPickerChanged', dayStart, dayEnd);
};

return (_ctx: any,_cache: any) => {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dropdown, {
      id: "selectView",
      modelValue: selectedMonth.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedMonth).value = $event)),
      options: getMonthsOptions.value,
      optionLabel: "label",
      optionValue: "value",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (onChangeSelectedMonth($event.value)))
    }, null, 8, ["modelValue", "options"]),
    (!isDaysLoading.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (__props.weekOrDays === 'days')
            ? (_openBlock(), _createBlock(DaysListPickerSwiper, {
                key: 0,
                firstDayPicker: firstDaySelectedMounth.value,
                numDays: numDaysInSelectedMonth.value,
                defaultDay: selectedStartDate.value,
                limitDay: lastDaySelectedMounth.value,
                onOnChange: onDayChanged
              }, null, 8, ["firstDayPicker", "numDays", "defaultDay", "limitDay"]))
            : (_openBlock(), _createBlock(WeekDaysListPickerSwiper, {
                key: 1,
                firstDayPicker: firstDaySelectedMounth.value,
                defaultDay: selectedStartDate.value,
                onOnChange: onDayChanged
              }, null, 8, ["firstDayPicker", "defaultDay"]))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})