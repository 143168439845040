import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-week-days-picker-container" }
const _hoisted_2 = { class: "days-picker-container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "c-filter__day" }
const _hoisted_5 = { class: "c-filter__label" }

import { Ref, ref, onMounted } from 'vue';
import moment from 'moment';
import dateFormat from '@/helpers/DateFormat.helper';
import { useI18n } from 'vue-i18n';
import i18n from '@/i18n';

interface DayPick {
  number: string;
  letter: string;
  dateStart: string;
  dateEnd: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'WeekDaysListPickerSwiper',
  props: {
  firstDayPicker: {
    type: String,
    required: true,
  },
  defaultDay: {
    type: String,
    required: true,
  },
},
  emits: ['onChange'],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const props = __props;

const { t } = useI18n();
const daySelected = ref(0);
const days: Ref<DayPick[]> = ref([]);
const defaultDayMutable = ref(props.defaultDay);

onMounted(() => {
  calculateDays();
});

function calculateDays(): void {
  moment.locale(i18n.global.locale.value);
  const weekDays: DayPick[] = [];
  const today = moment(new Date());
  const startOfWeek = moment(defaultDayMutable.value).startOf('week').weekday(0);

  for (let i = 0; i < 7; i++) {
    const newDay: DayPick = {
      dateStart: dateFormat.startOfCertainDay(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DDTHH:mm:ss')),
      dateEnd: dateFormat.endOfCertainDay(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DDTHH:mm:ss')),
      number: startOfWeek.clone().add(i, 'days').format('D MMM'),
      letter: startOfWeek.clone().add(i, 'days').format('dd'),
    };
    if (moment(newDay.dateStart).isSame(today, 'day')) {
      newDay.number = t('common.today');
    }

    weekDays.push(newDay);
  }

  daySelected.value = weekDays.findIndex((date) => moment(date.dateStart).isSame(props.defaultDay, 'day'));

  if (daySelected.value !== -1) {
    emits('onChange', weekDays[daySelected.value]?.dateStart, weekDays[daySelected.value]?.dateEnd);
  }

  days.value = weekDays;
}

const emitUpdateData = (date: DayPick): void => {
  emits('onChange', date.dateStart, date.dateEnd);
};

const onDayClick = async (date: DayPick, index: number): Promise<void> => {
  daySelected.value = index;
  emitUpdateData(date);
};

const goToWeek = (direction: 'previous' | 'next'): void => {
  if (days.value === undefined) return;

  const previousDaySelected = daySelected.value;
  const weekModifier = direction === 'previous' ? -1 : 1;

  defaultDayMutable.value = moment(defaultDayMutable.value).add(weekModifier, 'week').format('YYYY-MM-DDTHH:mm:ss');

  calculateDays();
  daySelected.value = previousDaySelected;

  const selectedDay = days.value[daySelected.value];
  emitUpdateData(selectedDay);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "c-button c-button--previous",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (goToWeek('previous')))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "icon-chevron_big_left" }, null, -1)
    ])),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(days.value, (day, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["c-filter", {
          'c-filter--active': index === daySelected.value,
        }]),
          onClick: ($event: any) => (onDayClick(day, index))
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(day.letter), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(day.number), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("button", {
      class: "c-button c-button--next",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (goToWeek('next')))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("i", { class: "icon-chevron_big_right" }, null, -1)
    ]))
  ]))
}
}

})